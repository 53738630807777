import { Badge, Box, Checkbox, Group, Skeleton, Stack, Text } from '@mantine/core';
import { Row } from '@tanstack/table-core';
import { AssetRow } from '@/app/assets/components/table/hooks/types';
import RawModelPreview from '@/app/assets/components/table/atoms/RawModelPreview';
import { AssetWarningsTooltip } from '@/app/assets/components/table/columns/basicInfoColumn/AssetWarningsTooltip';
import { AssetErrorsTooltip } from '@/app/assets/components/table/columns/basicInfoColumn/AssetErrorsTooltip';
import { truncateStringInMiddle } from '@/utils/truncateString';
import { UploadStatus } from '@/app/assets/components/table/columns/basicInfoColumn/UploadStatus';
import { rawModelErrorStates } from '@/app/assets/actions/utils';
import { getAssetErrors } from '@/app/assets/utils/getAssetErrors';
import { useAssetsUploadContext } from '../../../upload/contexts/useAssetsUploadContext';
import { useMemo } from 'react';
import { AssetName } from '@/app/assets/details/atoms/AssetName';

type NameColumnProps = {
  item: AssetRow['basicInfo'];
  row: Row<AssetRow>;
  useOnlyOutputs: boolean;
};

export function BasicInfoColumn({ item, row, useOnlyOutputs }: NameColumnProps) {
  const isImportingFailed = row.original.uploadStatus && rawModelErrorStates.has(row.original.uploadStatus);
  const { filesPendingUpload } = useAssetsUploadContext();

  const isBeingUploaded = useMemo(() => {
    return !!filesPendingUpload.find((file) => file.id === row.original.basicInfo.id);
  }, [filesPendingUpload, row.original.basicInfo.id]);

  const errors = getAssetErrors(row.original, isBeingUploaded);
  const warnings = row.original.warnings ?? [];

  const hasErrorsOrWarnings = errors.length > 0 || warnings.length > 0;

  return (
    <Group m={0} justify='flex-start' wrap='nowrap'>
      <RawModelPreview
        rowType={item.rowType}
        disabled={item.disabled ?? false}
        thumbnail={item.thumbnail}
        useOnlyOutputs={useOnlyOutputs}
      />
      {item.rowType === 'raw' && item.disabled && (
        <Skeleton visible={true} width={60} height={60} animate={!isImportingFailed}></Skeleton>
      )}
      {row.depth > 0 && (
        //This Box increases checkboxes hitbox area for output models.
        <Box
          onClick={(e) => {
            e.stopPropagation();
            row.getToggleSelectedHandler()(e);
          }}
          p={10}
          m={-10}
          style={{ cursor: 'default' }}
        >
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              onChange: row.getToggleSelectedHandler(),
              onClick: (e) => e.stopPropagation(),
            }}
          />
        </Box>
      )}

      <Stack justify='flex-start' gap='xs'>
        <Group wrap='nowrap' gap='xs'>
          <AssetName
            id={item.id}
            name={item.name}
            isOutput={item.rowType === 'output'}
            inTableRow={true}
            disabled={!(item.rowType === 'raw') && !(item.rowType === 'output')}
            renameIconPosition={hasErrorsOrWarnings ? 'left' : 'right'}
          />

          <Group gap='xs'>
            <AssetErrorsTooltip
              errors={errors}
              assetDescription={{
                id: row.original.basicInfo.id,
                name: row.original.basicInfo.name,
                type: row.original.basicInfo.rowType === 'raw' ? 'raw' : 'rapid',
              }}
            />
            <AssetWarningsTooltip warnings={warnings} />
          </Group>

          <UploadStatus status={row.original.uploadStatus} isBeingUploaded={isBeingUploaded} />
        </Group>
        {!!item.outputsCount && (
          <Text c='gray.6' size='sm'>
            {item.outputsCount} output {item.outputsCount === 1 ? 'model' : 'models'}
          </Text>
        )}
      </Stack>
      {item.workFlowName && (
        <Badge tt='none' color='grape' p='sm'>
          <Text className='text-nowrap' truncate='end'>
            {item.workFlowName}
          </Text>
        </Badge>
      )}
    </Group>
  );
}
